@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@font-face {
    font-family: 'Avenir Next LT Pro Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Condensed'), url(/static/media/AvenirNextLTPro-Cn.22a866d6.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi'), url(/static/media/AvenirNextLTPro-Demi.09cc1349.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi Condensed'), url(/static/media/AvenirNextLTPro-DemiCn.0f0ca3b6.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Heavy Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Heavy Condensed'), url(/static/media/AvenirNextLTPro-HeavyCn.543a43a1.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Medium Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Medium Condensed'), url(/static/media/AvenirNextLTPro-MediumCn.503dd918.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Regular'), url(/static/media/AvenirNextLTPro-Regular.a6f907b6.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Ultra Light Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Ultra Light Condensed'), url(/static/media/AvenirNextLTPro-UltLtCn.968edc59.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold'), url(/static/media/AvenirNextLTPro-Bold.58141e84.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold Condensed'), url(/static/media/AvenirNextLTPro-BoldCn.8c49ae96.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold Condensed Italic'), url(/static/media/AvenirNextLTPro-BoldCnIt.b0121ef5.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Condensed Italic'), url(/static/media/AvenirNextLTPro-CnIt.86f9f514.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi Condensed Italic'), url(/static/media/AvenirNextLTPro-DemiCnIt.ff1ca902.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi Italic'), url(/static/media/AvenirNextLTPro-DemiIt.e929d7cb.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Heavy Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Heavy Condensed Italic'), url(/static/media/AvenirNextLTPro-HeavyCnIt.6ae337ac.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Italic'), url(/static/media/AvenirNextLTPro-It.0a2a0a5f.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Medium Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Medium Condensed Italic'), url(/static/media/AvenirNextLTPro-MediumCnIt.a2b7a0d7.woff) format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Ultra Light Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Ultra Light Condensed Italic'), url(/static/media/AvenirNextLTPro-UltLtCnIt.0276ff87.woff) format('woff');
}
html {
  font-size:62.5%;
  font-family:'Avenir Next LT Pro Regular','Open Sans', sans-serif;
  background:#f2f3f6;
}

@media screen and (min-width: 641px) {
  html {
    font-size: 59%;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 62.5%;
  }
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
  color: #50595e;
  font-family:'Avenir Next LT Pro Regular','Open Sans', sans-serif;
}
button{
  font-family: 'Avenir Next LT Pro Regular','Open Sans', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  font-size: 16px;
  color: 'gray';
}

svg {
  overflow: visible;
  
}

text {
  fill: #71777C;
  color: #71877C;
  font-size: 0.80rem;
}
text.textOnChartPoint{
  font-size:0.80rem;
  fill: #71777C;
  color: #71877C;
  font-weight:bold;
}

.subtitle text {
  text-decoration: underline;
  font-size: 12px;
}

.axis .domain,
.axis.xAxis line {
  display: none;
}

.tick line{
  opacity: 0.2;
  stroke-dasharray: 5,5;
}

.domain{
  visibility: hidden;
}

a:-webkit-any-link,a{
  color: #22a6f5;
  text-decoration:none;
}
a:visited{
  color:#22a6f5 ;
  text-decoration:none;
}

